import {IoClose} from "react-icons/io5";
import React from "react";

export default function MaterialTable ({values, setValues}) {

    return (

        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <h2>Materiali</h2>
            <table style={{width: "100%", maxWidth: "600px"}}>
                <thead>
                <tr>
                    <th>Nome</th>
                    <th>Quantità</th>
                    <th>Unità</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {
                    values.materials.map((val, idx) => {
                        let materialName = `material-${idx}`, quantity = `quantity-${idx}`,
                            unit = `unit-${idx}`
                        return (
                            <tr key={val.index}>
                                <td style={{paddingInline: "10px"}}>
                                                <textarea name="name" data-id={idx} id={materialName}
                                                          className="form__field" placeholder="Nome"
                                                          value={val.name}
                                                          onChange={(e) => {
                                                              let newMaterials = [...values.materials]
                                                              newMaterials[idx].name = e.target.value
                                                              setValues.setMaterials(newMaterials)
                                                          }}
                                                />
                                </td>
                                <td style={{paddingInline: "10px"}}>
                                    <input type="number" name="quantity" data-id={idx} inputMode="decimal"
                                           pattern="[0-9]*([,.][0-9]+)?" id={quantity}
                                           className="form__field" placeholder="Quantità"
                                           style={window.innerWidth < 600 ? {maxWidth: 80} : {maxWidth: 120}}
                                           value={val.quantity}
                                           onChange={(e) => {
                                               let newMaterials = [...values.materials]
                                               newMaterials[idx].quantity = e.target.value
                                               setValues.setMaterials(newMaterials)
                                           }}
                                    />
                                </td>
                                <td style={{paddingInline: "10px"}}>
                                    <select name="unit" data-id={idx} id={unit} className="form__field"
                                            placeholder="Unità" style={{minWidth: 40}}
                                            value={val.unit}
                                            onChange={(e) => {
                                                let newMaterials = [...values.materials]
                                                newMaterials[idx].unit = e.target.value
                                                setValues.setMaterials(newMaterials)
                                            }}
                                    >
                                        <option value="n">n</option>
                                        <option value="m">mt</option>
                                        <option value="kg">kg</option>
                                        <option value="l">l</option>
                                        <option value="ton">ton</option>
                                        <option value="m2">m2</option>
                                        <option value="m3">m3</option>
                                    </select>
                                </td>
                                <td style={{paddingInline: "0px"}}>
                                    <IoClose color={"grey"} size={24} style={{cursor: "pointer"}}
                                             onClick={() => {
                                                 if (values.materials.length === 1) {
                                                     setValues.setMaterials([{name: "", quantity: "", unit: "n"}])
                                                     return
                                                 }
                                                 let newMaterials = [...values.materials]
                                                 // remove current row
                                                 newMaterials.splice(idx, 1)
                                                 setValues.setMaterials(newMaterials)
                                             }}/>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
    )

}