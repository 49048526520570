import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import React from "react";

export default function InfoForm ({values, setValues}) {

    return (
        <form style={{width: "100%", maxWidth: "600px"}}>

            <div className="form__group">
                <input type="text" className="form__field" id='customer' name="customer" placeholder="Cliente"
                       value={values.customer}
                       onChange={e => setValues.setCustomer(e.target.value)}
                />
                <label className="form__label">Cliente</label>
            </div>

            <div className="form__group">
                <textarea className="form__field" id="description" placeholder="Descrizione"
                          value={values.description}
                          onChange={e => setValues.setDescription(e.target.value)}
                />
                <label className="form__label">Descrizione</label>
            </div>


            <div className="form__group">
                <textarea className="form__field" id='notes' placeholder="Note"
                          value={values.notes}
                          onChange={e => setValues.setNotes(e.target.value)}
                />
                <label className="form__label">Note</label>
            </div>


            <div className="form__group"
                 style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <h3>Reperibilità</h3>
                <ToggleSwitch
                    value={[values.onCall, setValues.setOnCall]}
                ></ToggleSwitch>
            </div>


            <div className="form__group"
                 style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <h3>Completato</h3>
                <ToggleSwitch
                    value={[values.completed, setValues.setCompleted]}
                ></ToggleSwitch>
            </div>

        </form>
    )
}
