import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Image from "image-js";

import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingError from "../../components/LoadingError";
import NavBar from "../../components/NavBar";
import inputFieldChecker from "../../functions/inputFieldChecker";
import addEquipmentLineIfLastFilled from "../../components/functions/addEquipmentLineIfLastFilled";
import addLabourLineIfLastFilled from "../../components/functions/addLabourLineIfLastFilled";
import addMaterialLineIfLastFilled from "../../components/functions/addMaterialLineIfLastFilled";
import InfoForm from "../../components/Add/InfoForm";
import ImageForm from "../../components/Add/ImageForm";
import MaterialTable from "../../components/Add/MaterialTable";
import EquipmentTable from "../../components/Add/EquipmentTable";
import DayTable from "../../components/Add/DayTable";
import toHtmlInputDate from "../../components/functions/toHtmlInputDate";


const Add = () => {

    //Navigation
    const navigate = useNavigate();

    //Page state
    const [loadingError, setLoadingError] = React.useState(false);
    const [errorType, setErrorType] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [loadingMessage, setLoadingMessage] = useState("")
    //Page data
    const [customer, setCustomer] = React.useState(localStorage.getItem("latestWork") ? JSON.parse(localStorage.getItem("latestWork")).work.customer : "");
    const [description, setDescription] = React.useState(localStorage.getItem("latestWork") ? JSON.parse(localStorage.getItem("latestWork")).work.description : "");
    const [notes, setNotes] = React.useState(localStorage.getItem("latestWork") ? JSON.parse(localStorage.getItem("latestWork")).work.notes : "");
    const [completed, setCompleted] = React.useState(localStorage.getItem("latestWork") ? JSON.parse(localStorage.getItem("latestWork")).work.completed : false);
    const [onCall, setOnCall] = React.useState(localStorage.getItem("latestWork") ? JSON.parse(localStorage.getItem("latestWork")).work.onCall : false);
    // Add images as an array of files
    const [newImages, setNewImages] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [vehicles, setVehicles] = React.useState([]);
    const [equipmentsList, setEquipmentsList] = React.useState([]);
    const [equipments, setEquipments] = React.useState(localStorage.getItem("latestWork") ? JSON.parse(localStorage.getItem("latestWork")).work.equipments : []);
    const [materials, setMaterials] = React.useState(localStorage.getItem("latestWork") ? JSON.parse(localStorage.getItem("latestWork")).work.materials : []);
    const [labour, setLabour] = React.useState( localStorage.getItem("latestWork") ? JSON.parse(localStorage.getItem("latestWork")).work.labour : [{ date: toHtmlInputDate(new Date()), users: [], vehicles: [] }]);

    const infoFormValues = {customer, description, notes, completed, onCall}
    const infoFormSetValues = {setCustomer, setDescription, setNotes, setCompleted, setOnCall}

    const imagesFormValues = {newImages}
    const imagesFormSetValues = {setNewImages}

    const materialTableValues = {materials}
    const materialTableSetValues = {setMaterials}

    const equipmentTableValues = {equipments, equipmentsList}
    const equipmentTableSetValues = {setEquipments}

    const labourTableValues = {labour, users, vehicles}
    const labourTableSetValues = {setLabour}


    const saveValues = () => {
        const latestWork = {
            timestamp: new Date(),
            work: {
                customer: customer,
                description: description,
                notes: notes,
                completed: completed,
                onCall: onCall,
                materials: materials,
                equipments: equipments,
                labour: labour,
                vehicles: vehicles,
            }
        }

        localStorage.setItem("latestWork", JSON.stringify(latestWork))
    }

    useEffect(() => {
        saveValues()
    }, [customer, description, notes, completed, onCall, materials, equipments, labour, vehicles])

    //Loading needed data
    useEffect(() => {

        fetch(process.env.REACT_APP_BACKEND_URL + "/users", {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        setUsers(data.users);
                    });
                }
                else {
                    setErrorType("Il server non ha accettato tale richiesta; ecco il messaggio nel dettaglio: " + response.message)
                    setLoadingError(true);
                }
            })
            .catch(() => {
                setErrorType("Errore di rete nel caricamento dei dati: verifica la tua connessione e riprova")
                setLoadingError(true);
            });

        // Fetch vehicle list
        fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles", {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        setVehicles(data.vehicles);
                    });
                }
                else {
                    setErrorType("Il server non ha accettato tale richiesta; ecco il messaggio nel dettaglio: " + response.message)
                    setLoadingError(true);
                }
            })
            .catch(() => {
                setErrorType("Errore di rete nel caricamento dei dati: verifica la tua connessione e riprova")
                setLoadingError(true);
            });

        // Fetch equipment list
        fetch(process.env.REACT_APP_BACKEND_URL + "/equipments", {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        setEquipmentsList(data.equipments);
                    });
                }
                else {
                    setErrorType("Il server non ha accettato tale richiesta; ecco il messaggio nel dettaglio: " + response.message)
                    setLoadingError(true);
                }
            })
            .catch(() => {
                setErrorType("Errore di rete nel caricamento dei dati: verifica la tua connessione e riprova")
                setLoadingError(true);
        })

    }, []);

    useEffect(() => {
        if (users.length > 0 && vehicles.length > 0 && equipmentsList.length > 0) {
            setIsLoading(false);
        }
    }, [users, vehicles, equipmentsList]);
    //End of loading needed data



    useEffect(() => {
        addMaterialLineIfLastFilled(materials, setMaterials)
    }, [materials]);

    useEffect(() => {
        addLabourLineIfLastFilled(labour, setLabour)
    }, [labour]);


    useEffect(() => {
        addEquipmentLineIfLastFilled(equipments, setEquipments)
    }, [equipments]);



    const uploadImages = async (workId) => {

        try {

            /* it requires to much memory
            const resizedImages = await Promise.all(newImages.map(async (image) => {
                const imageBuffer = await image.arrayBuffer();
                const imageJs = await Image.load(new Uint8Array(imageBuffer));
                const resizedImage = await imageJs.resize({ width: 2560 });
                const resizedImageBuffer = await resizedImage.toBuffer();
                const resizedImageBlob = new Blob([resizedImageBuffer], { type: "image/jpeg" });
                return new File([resizedImageBlob], image.name, { type: "image/jpeg" });
            }));
             */

            for (const image of newImages) {
                setLoadingMessage("Compressione foto " + (newImages.indexOf(image) + 1) + " di " + newImages.length)
                const imageBuffer = await image.arrayBuffer();
                const imageArray = new Uint8Array(imageBuffer);
                const imageJs = await Image.load(imageArray);
                const resizedImage = await imageJs.resize({width: 2560});
                const resizedImageBuffer = await resizedImage.toBuffer();
                const resizedImageArray = new Uint8Array(resizedImageBuffer);
                const resizedImageBlob = new Blob([resizedImageArray], {type: "image/jpeg"});
                const resizedImageFile = new File([resizedImageBlob], image.name, {type: "image/jpeg"});
                setNewImages(newImages => [...newImages, resizedImageFile]);
            }


            setLoadingMessage("Avvio caricamento delle immagini...")

            for (const image of newImages) {

                setLoadingMessage("Caricamento immagine " + (newImages.indexOf(image) + 1) + " di " + newImages.length)

                let formData = new FormData();
                formData.append("photo", image);

                const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/works/" + workId + "/images", {
                    method: "POST",
                    credentials: "include",
                    body: formData
                })

                if (response.status !== 200) {
                    setErrorType("Il server non ha accettato tale richiesta; ecco il messaggio nel dettaglio: " + response.message)
                    setLoadingError(true)
                    return
                }

            }

        } catch (error) {
            setErrorType("Errore di rete nel caricamento di un'immagine: verifica la tua connessione e riprova")
            setLoadingError(true)
        }
    }


    //Upload the work to the backend
    const save = async (e) => {


        // meaning that the default action that belongs to the event will not occur.
        // In this case do not send as a normal www-form-urlencoded form, but as a json with fetch
        e.preventDefault();

        const inputField = {
            customer: customer,
            description: description,
            notes: notes,
            completed: completed,
            oncall: onCall,
            materials: materials,
            equipments: equipments,
            labour: labour,
            vehicles: vehicles,
        }

        let validatedInput

        try {
            validatedInput = inputFieldChecker(inputField)
        } catch (error) {
            alert("Errore nel salvataggio del rapportino: nel dettaglio " + error.message)
            return
        }

        setIsLoading(true)

        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/works", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(validatedInput),
            })

            if (response.status === 200) {
                const responseJson = await response.json()
                await uploadImages(responseJson.workId);
                if (!loadingError) {
                    await localStorage.removeItem("latestWork")
                    navigate("/home")
                }
            } else {
                const responseJson = await response.json()
                setErrorType("Il server non ha accettato tale richiesta; ecco il messaggio nel dettaglio: " + responseJson.message)
                setLoadingError(true)
            }

        } catch (error) {
            setErrorType("Errore di rete nel caricamento del rapportino: verifica la tua connessione e riprova")
            setLoadingError(true)
        }

    }

    return (
        loadingError ? <LoadingError errorDescription={errorType}/> :
            isLoading ? <LoadingSpinner message={loadingMessage} /> :
                <div className="mainContainer">

                    <NavBar/>

                    <h1>Nuovo Rapportino</h1>

                    <InfoForm values={infoFormValues} setValues={infoFormSetValues}/>

                    <ImageForm values={imagesFormValues} setValues={imagesFormSetValues}  />

                    <MaterialTable values={materialTableValues} setValues={materialTableSetValues} />

                    <EquipmentTable values={equipmentTableValues} setValues={equipmentTableSetValues} />

                    <DayTable values={labourTableValues} setValues={labourTableSetValues} />

                    <button className="button" onClick={save}>
                        Salva
                    </button>
                </div>
    )
}

export default Add;


