import "./ToggleSwitch.css";
import React from "react";

const ToggleSwitch = (props) => {

        const [value, setValue] = props.value;

        //edit here css content value in inner:after to change the text

        return (
            <div className="container">
                <div className="form__group">
                    <label className="switch">
                        <input className="cb" type="checkbox" value={value}
                               onChange={(e) =>
                                   setValue(e.target.checked)
                        }/>
                        <span className="toggle">
                            <span className="left">no</span>
                            <span className="right">si</span>
                        </span>
                    </label>
                </div>
            </div>


        );
}

export default ToggleSwitch;